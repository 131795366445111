@tailwind base;
@tailwind components;
@tailwind utilities;


@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";


@font-face {
    font-family: 'Agrandir';
    src: url('./fonts/Agrandir-Regular.otf') format('opentype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Agrandir';
    src: url('./fonts/Agrandir-GrandLight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Agrandir';
    src: url('./fonts/Agrandir-WideLight.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-stretch: expanded;
}

@font-face {
    font-family: 'Agrandir';
    src: url('./fonts/Agrandir-TextBold.otf') format('opentype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Agrandir';
    src: url('./fonts/Agrandir-GrandHeavy.otf') format('opentype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Agrandir';
    src: url('./fonts/Agrandir-WideBlackItalic.otf') format('opentype');
    font-weight: 900;
    font-style: italic;
    font-stretch: expanded;
}

@font-face {
    font-family: 'Agrandir';
    src: url('./fonts/Agrandir-ThinItalic.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Agrandir';
    src: url('./fonts/Agrandir-Tight.otf') format('opentype');
    font-weight: 100;
    font-style: italic;
    font-stretch: normal;
}

@font-face {
    font-family: 'Agrandir';
    src: url('./fonts/Agrandir-Narrow.otf') format('opentype');
    font-weight: 300;
    font-style: normal;
    font-stretch: narrower;
}

body {
    font-family: 'Agrandir', sans-serif;
}

.debug-red {
    border: 2px solid red;
}

.debug-blue {
    border: 2px solid blue;
}

.debug-green {
    border: 2px solid green;
}

.debug-yellow {
    border: 2px solid yellow;
}